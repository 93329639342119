// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from 'firebase/auth';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBqFYUTK4O597VWJ22OSb3Q4PZWgtuKGA0",
  authDomain: "moo-1d019.firebaseapp.com",
  projectId: "moo-1d019",
  storageBucket: "moo-1d019.appspot.com",
  messagingSenderId: "213864670160",
  appId: "1:213864670160:web:3e9fe535bc26fa40d90fd5",
  measurementId: "G-4K5XTDKWJ3"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);



function connectEmulators() {
  const auth = getAuth()
  connectAuthEmulator(auth, "http://localhost:9099");
}

// connectEmulators()
